import React from "react";
import "./blogsidebar.css";
import { IoMdSearch } from "react-icons/io";

function BlogSidebar() {

    return (
        <div className="b-r-container">
            <div className="keyword-input-box">
                <input type="text" placeholder="Enter Keyboard" />
                <div className="input-right-box"><IoMdSearch /></div>
            </div>
            <div className="b-r-categories">
                <div className="b-r-c-head">Categories</div>
                <div className="b-r-c-item">Empowerment Stories</div>
                <div className="b-r-c-item">Health and Wellness</div>
                <div className="b-r-c-item">Community Engagement</div>
                <div className="b-r-c-item">Events and Celebrations</div>
            </div>
            <div className="b-r-recent-posts">
                <div className="b-r-rp-head">Recent Posts</div>
                <div className="b-r-rp-item">
                    <div className="rp-left">
                        <img src="/assets/news-1.webp" alt="" />
                    </div>
                    <div className="rp-right">
                        {/* <div className="rp-r-top">
                            <span>By admin</span>
                            <span>2 Comments</span>
                        </div> */}
                        <p>
                            Breaking Barriers: How Kishori Priya Foundation Empowers Elderly
                            Women .....
                        </p>
                    </div>
                </div>
                <div className="b-r-rp-item">
                    <div className="rp-left">
                        <img src="/assets/news-2.webp" alt="" />
                    </div>
                    <div className="rp-right">
                        {/* <div className="rp-r-top">
                            <span>By admin</span>
                            <span>2 Comments</span>
                        </div> */}
                        <p>
                            Breaking Barriers: How Kishori Priya Foundation Empowers Elderly
                            Women .....
                        </p>
                    </div>
                </div>
            </div>
            <div className="b-r-popular-tags">
                <div className="b-r-pt-head">Popular Tags</div>
                <div className="b-r-pt-item">
                    <div>#ElderlyEmpowerment</div>
                    <div>#HealthyAging</div>
                    <div>#CommunityEngagement</div>
                    <div>#SkillDevelopment</div>
                    <div>#CelebratingWisdom</div>
                    <div>#VolunteerStories</div>
                </div>
            </div>
        </div>
    );
}

export default BlogSidebar;
