import React from 'react'
import "./news.css"
import { FaArrowRightLong } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom';

const News = () => {

    const navigate = useNavigate();

    return (
        <div className='news'>
            <div className='news-header'>
                <h6>- News & articles</h6>
                <h1>Directly From The Latest News and Articles</h1>
            </div>

            <div className="news-cards-cont">

                <div className="news-card">
                    <div className='top-news-card'>
                        <div className="news-date">
                            <span>1 march 2024</span>
                        </div>
                        <div className="news-image">
                            <img src="/assets/news-1.webp" alt="" />
                        </div>
                        <div className="news-reviews">
                            <span>By admin</span>
                            <span>2 Comments</span>
                        </div>
                        <div className="news-headline">
                            <p>Breaking Barriers: How Kishori Priya Foundation Empowers Elderly Women Through Entrepreneurship</p>
                        </div>
                    </div>
                    <div className="news-read-more-cont">
                        <button onClick={() => navigate('/blog/details')}>Read more</button>
                        <span><FaArrowRightLong /></span>
                    </div>
                </div>
                <div className="news-card">
                    <div className='top-news-card'>
                        <div className="news-date">
                            <span>1 march 2024</span>
                        </div>
                        <div className="news-image">
                            <img src="/assets/news-2.webp" alt="" />
                        </div>
                        <div className="news-reviews">
                            <span>By admin</span>
                            <span>2 Comments</span>
                        </div>
                        <div className="news-headline">
                            <p>Prioritizing Mental Health: Supporting Elderly Women's Well-Being at Kishori Priya</p>
                        </div>
                    </div>
                    <div className="news-read-more-cont">
                        <button onClick={() => navigate('/blog/details')}>Read more</button>
                        <span><FaArrowRightLong /></span>
                    </div>
                </div>
                <div className="news-card">
                    <div className='top-news-card'>
                        <div className="news-date">
                            <span>1 march 2024</span>
                        </div>
                        <div className="news-image">
                            <img src="/assets/news-3.webp" alt="" />
                        </div>
                        <div className="news-reviews">
                            <span>By admin</span>
                            <span>2 Comments</span>
                        </div>
                        <div className="news-headline">
                            <p>Aging Gracefully: Holistic Care for Elderly Women at Kishori Priya Foundation</p>
                        </div>
                    </div>
                    <div className="news-read-more-cont">
                        <button onClick={() => navigate('/blog/details')}>Read more</button>
                        <span><FaArrowRightLong /></span>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default News