import React from 'react'
import "./joinus.css"
import { useNavigate } from "react-router-dom";

const JoinUs = () => {

    const navigate = useNavigate();

    return (
        <div className='joinus'>
            <div className="joinus-cont">
                <h1>Join Us in Providing a Better Life for Our <span>Seniors Citizens</span></h1>
                <button onClick={() => navigate('/donate')}>Donate Now</button>
            </div>
        </div>
    )
}

export default JoinUs