import React from "react";
import './blog.css';
import { Navbar } from "../../components/navbar/Navbar";
import { FaArrowRightLong } from "react-icons/fa6";
import BlogSidebar from "../../components/blogSidebar/BlogSidebar";
import Footer from "../../components/footer/Footer";
import { useNavigate } from "react-router-dom";

function Blog() {

    const navigate = useNavigate();

    return (
        <div className="blog">
            <Navbar />
            <div className='blog-header'>
                <h1>Blogs</h1>
                <h5>Home &gt; <span>Blog</span></h5>
            </div>

            <div className="blog-mid">
                <div className="b-m-left">
                    <div className="b-m-l-cards">
                        <div className="b-m-l-card">
                            <div className="b-date">
                                <div className="top-blog-card">
                                    <span>1 march 2024</span>
                                </div>
                                <div className="blog-image">
                                    <img src="/assets/news-1.webp" alt="" />
                                </div>
                                <div className="blog-reviews">
                                    <span>By admin</span>
                                    <span>2 Comments</span>
                                </div>
                                <div className="blog-headline">
                                    <p>
                                        Breaking Barriers: How Kishori Priya Foundation Empowers
                                        Elderly Women Through Entrepreneurship
                                    </p>
                                </div>
                            </div>
                            <div className="blog-read-more-cont">
                                <button onClick={() => navigate('/blog/details')}>Read more</button>
                                <span>
                                    <FaArrowRightLong />
                                </span>
                            </div>
                        </div>
                        <div className="b-m-l-card">
                            <div className="b-date">
                                <div className="top-blog-card">
                                    <span>1 march 2024</span>
                                </div>
                                <div className="blog-image">
                                    <img src="/assets/news-2.webp" alt="" />
                                </div>
                                <div className="blog-reviews">
                                    <span>By admin</span>
                                    <span>2 Comments</span>
                                </div>
                                <div className="blog-headline">
                                    <p>
                                        Prioritizing Mental Health: Supporting Elderly Women's
                                        Well-Being at Kishori Priya
                                    </p>
                                </div>
                            </div>
                            <div className="blog-read-more-cont">
                                <button onClick={() => navigate('/blog/details')}>Read more</button>
                                <span>
                                    <FaArrowRightLong />
                                </span>
                            </div>
                        </div>
                        <div className="b-m-l-card">
                            <div className="b-date">
                                <div className="top-blog-card">
                                    <span>1 march 2024</span>
                                </div>
                                <div className="blog-image">
                                    <img src="/assets/news-3.webp" alt="" />
                                </div>
                                <div className="blog-reviews">
                                    <span>By admin</span>
                                    <span>2 Comments</span>
                                </div>
                                <div className="blog-headline">
                                    <p>
                                        Aging Gracefully: Holistic Care for Elderly Women at Kishori
                                        Priya Foundation
                                    </p>
                                </div>
                            </div>
                            <div className="blog-read-more-cont">
                                <button onClick={() => navigate('/blog/details')}>Read more</button>
                                <span>
                                    <FaArrowRightLong />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="b-m-right">
                    <BlogSidebar />
                </div>
            </div>

            <div className="bottom-blog">
                <h6>The beauty of life does not depend on how happy you are, but on <span>how happy</span> others can be <span>because of you</span></h6>
            </div>
            <Footer />
        </div>
    );
}

export default Blog;
