import React, { useEffect, useState } from "react";
import "./home.css";
import { Navbar } from "../../components/navbar/Navbar";
import Banner from "../../components/banner/Banner";
import About from "../../components/about/About";
import JoinUs from "../../components/joinUs/JoinUs";
import Campigns from "../../components/campigns/Campigns";
import Footer from "../../components/footer/Footer";
// import Explore from "../../components/explore/Explore";
import Services from "../../components/services/Services";
import News from "../../components/news/News";
import Programs from "../../components/programs/Programs";
import Support from "../../components/support/Support";
import Contact from "../../components/contact/Contact";
import { axiosRequest } from '../../services/ApiCall';

export const Home = () => {

    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        // Fetch the campaigns from the backend
        const fetchCampaigns = async () => {
            try {
                const { data } = await axiosRequest.get("/campaign/get");
                setCampaigns(data.campaign);
            } catch (error) {
                console.error("Error fetching campaigns:", error);
            }
        };

        fetchCampaigns();
    }, []);

    return (
        <div className="home">
            <Navbar />
            <Banner />
            <About />
            <JoinUs />
            <Campigns campaigns={campaigns} />
            {/* <Explore /> */}
            <Programs campaigns={campaigns} />
            <Services />
            <News />
            <Contact />
            <Support />
            <Footer />
        </div>
    );
};