import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Home } from "./pages/home/Home";
import BlogDetail from './pages/blogDetail/BlogDetail';
import Blog from './pages/blog/Blog';
import PaymentSuccess from './pages/paymentSuccess/PaymentSuccess';
import Donate from './pages/donate/Donate';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/details' element={<BlogDetail />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/paymentsuccess" element={<PaymentSuccess />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;