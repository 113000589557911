import React from 'react'
import "./blogdetail.css";
import { Navbar } from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import BlogSidebar from '../../components/blogSidebar/BlogSidebar';

const BlogDetail = () => {
    return (
        <div className='blog-detail'>
            <Navbar />
            <div className="blog-detail-cont">
                <div className='bd-header'>
                    <h1>Blogs</h1>
                    <h5>Home &gt; <span>Blog</span></h5>
                </div>

                <div className="bd-cont">
                    <div className="left-bd">
                        <div className="bd-headline">
                            <h1>Prioritizing Mental Health: Supporting Elderly Women's Well-Being at Kishori Priya</h1>
                            <div>
                                <span>1 MARCH 2024</span>
                                <span>5 min Read</span>
                            </div>
                        </div>
                        <div className="blog-detail-img">
                            <img src="/assets/image1.webp" alt="" />
                        </div>
                        <div className="bd-text-cont">
                            <p>In the journey of aging, mental health often takes a back seat. However, at Kishori Priya, mental health is a priority, especially for the elderly women who call it home. The foundation recognizes the unique challenges faced by elderly women and has implemented various initiatives to support their mental well-being.</p>
                            <p>One of the key programs at Kishori Priya is the mental health awareness and counseling program. Through this program, elderly women receive education about mental health issues and have access to professional counseling services. This proactive approach has helped many residents cope with loneliness, depression, and anxiety, which are common challenges faced by elderly women.</p>
                        </div>
                        <div className="blog-detail-img">
                            <img src="/assets/image 4.webp" alt="" />
                        </div>

                        <div className="bd-text-cont">
                            <p>Additionally, Kishori Priya organizes regular social activities and outings to keep residents engaged and connected. These activities not only provide a sense of belonging but also help in reducing feelings of isolation and loneliness. The foundation also encourages residents to pursue hobbies and interests, which has been shown to have a positive impact on mental health.</p>
                            <p>One resident, Mrs. Sharma, shares her experience of how the mental health support at Kishori Priya has transformed her life. After losing her husband, Mrs. Sharma struggled with depression and grief. However, through counseling and support groups at the foundation, she was able to find healing and a renewed sense of purpose. Today, Mrs. Sharma is an advocate for mental health awareness among the residents, helping others navigate their own mental health journeys.</p>
                            <p>The holistic approach to mental health at Kishori Priya extends beyond individual care. The foundation also focuses on creating a supportive and inclusive environment where residents feel valued and respected. This has led to a strong sense of community among the elderly women, providing them with a vital support system as they age.</p>
                            <p>As we continue to understand the importance of mental health in overall well-being, initiatives like those at Kishori Priya serve as a shining example of how prioritizing mental health can significantly improve the lives of elderly women. By providing them with the necessary support and resources, we can ensure that they age with dignity and grace, enjoying a fulfilling and meaningful life.</p>
                        </div>

                    </div>

                    <div className="right-bd">
                        <BlogSidebar />
                    </div>
                </div>

            </div>
            <div className="bottom-bd">
                <h6>The beauty of life does not depend on how happy you are, but on <span>how happy</span> others can be <span>because of you</span></h6>
            </div>
            <Footer />
        </div>
    )
}

export default BlogDetail