import React, { useEffect, useMemo } from 'react';
import "./banner.css";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import SwiperCore from "swiper";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function Banner() {

    // const imageSlider = [
    //     {
    //         id: 1,
    //         webp: "/assets/mata ji.webp",
    //         png: "/assets/mata ji.png",
    //         alt: "Mata Ji",
    //     },
    //     {
    //         id: 2,
    //         webp: "/assets/daily needs.webp",
    //         png: "/assets/daily needs.png",
    //         alt: "Daily Needs",
    //     },
    //     {
    //         id: 3,
    //         webp: "/assets/medical.webp",
    //         png: "/assets/medical.png",
    //         alt: "Medical",
    //     },
    // ];

    const imageSlider = useMemo(() => [
        {
            id: 1,
            webp: "/assets/mata ji.webp",
            png: "/assets/mata ji.png",
            alt: "Mata Ji",
        },
        {
            id: 2,
            webp: "/assets/daily needs.webp",
            png: "/assets/daily needs.png",
            alt: "Daily Needs",
        },
        {
            id: 3,
            webp: "/assets/medical.webp",
            png: "/assets/medical.png",
            alt: "Medical",
        },
    ], []);

    useEffect(() => {
        const preloads = imageSlider.map((image) => {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.href = image.webp;
            link.as = 'image';
            document.head.appendChild(link);
            return link;
        });

        return () => {
            preloads.forEach((link) => document.head.removeChild(link));
        };
    }, [imageSlider]);

    return (
        <div className='banner'>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                slidesPerView={1}
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
            >
                {imageSlider.map((image) => (
                    <SwiperSlide key={image.id}>
                        <picture>
                            {/* <source srcSet={image.webp} type="image/webp" />
                            <img src={image.png} alt={image.alt} /> */}
                            <img src={image.webp} alt={image.alt} />
                        </picture>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}


// import React from 'react'
// import "./banner.css"
// import "react-multi-carousel/lib/styles.css";
// import "swiper/swiper-bundle.css";
// import { Swiper, SwiperSlide } from "swiper/react";
// import {
//     Navigation,
//     Pagination,
//     Scrollbar,
//     A11y,
//     Autoplay,
// } from "swiper/modules";
// import SwiperCore from "swiper";

// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

// export default function Banner() {

//     const imageSlider = [
//         {
//             id: 1,
//             img: "/assets/mata ji.png",
//         },
//         {
//             id: 2,
//             img: "/assets/daily needs.png",
//         },
//         {
//             id: 3,
//             img: "/assets/medical.png",
//         },
//     ];

//     return (
//         <div className='banner'>
//             <Swiper
//                 modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
//                 // spaceBetween={10}
//                 slidesPerView={1}
//                 loop={true}
//                 // loopAdditionalSlides={1}
//                 //  autoplay={{ delay: 1000 }}
//                 // navigation={true}
//                 autoplay={{
//                     delay: 3000,
//                     disableOnInteraction: false,
//                 }}
//                 pagination={{ clickable: true }}
//             // scrollbar={{ draggable: true }}
//             // onSwiper={(swiper) => console.log(swiper)}
//             // onSlideChange={() => console.log('slide change')}
//             >
//                 {imageSlider.map((image) => (
//                     <SwiperSlide key={image.id}>
//                         {/* <NavLink to={`/productlist?${image.category}`}> */}
//                         {/* <Link to={image.link}> */}
//                         <img src={image.img} alt="" />
//                         {/* </Link> */}
//                         {/* </NavLink> */}
//                     </SwiperSlide>
//                 ))}
//             </Swiper>
//         </div>
//     )
// }