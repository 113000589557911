import React from 'react'
import "./support.css"
import { useNavigate } from "react-router-dom";

const Support = () => {

    const navigate = useNavigate();

    return (
        <div className='support'>
            <div className="support-cont">
                <div>
                    <h1>Support Our Cause | <span>Donate Today</span></h1>
                    <h6>Your small donation can bring a ray of sunshine to an elderly person's day. Every contribution, no matter the size, makes a meaningful difference in their lives</h6>
                </div>
                <div>
                    <button onClick={() => navigate('/donate')}>Donate Now</button>
                </div>
            </div>
        </div>
    )
}

export default Support